import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/ari-works-img/ktown.png";

import {
  AiOutlineSearch,
  AiOutlineShoppingCart,
  AiOutlineUser,
} from "react-icons/ai";

export default function Mobile() {
  let [menuBar, setMenuBar] = useState("collapse");
  const toggleMenu = () => {
    setMenuBar(menuBar === "collapse show" ? "collapse" : menuBar);
  };
  return (
    <>
      <Nav
        className="navbar navbar-expand-lg navbar-light shadow d-lg-none"
        style={{
          backgroundColor: "#fff",
          position: "sticky",
          top: 0,
          zIndex: 3,
        }}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <Link
            className="logo align-self-center"
            to="/"
            style={{ width: "80%" }}
          >
            <img src={Logo} alt="sivam farms logo" height="auto" width="30%" />
          </Link>

          <button
            className="navbar-toggler border-0"
            type="button"
            onClick={() => {
              toggleMenu();
            }}
            data-bs-toggle={menuBar}
            data-bs-target="#templatemo_main_nav"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="align-self-center collapse navbar-collapse flex-fill  d-lg-flex justify-content-lg-between"
            id="templatemo_main_nav"
          >
            <div className="flex-fill">
              <ul className="nav navbar-nav d-flex justify-content-between mx-lg-auto">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/"
                    onClick={() => toggleMenu()}
                  >
                    Home
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/beads">
                  Aari Material
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/thread">
                  Aari blouses and Embroidery
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/zardosi">
                  Brooches
                  </Link>
                </li>
                
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/cart/:productId"
                    className="nav-icon position-relative text-decoration-none"
                  >
                    <AiOutlineShoppingCart style={{ fontSize: "1.7em" }} />
                    {/* <label className="position-absolute top-0 left-100 translate-middle badge rounded-pill bg-danger text-light">
                      4
                    </label> */}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/profile/:productId"
                    className="nav-icon position-relative text-decoration-none"
                  >
                    <AiOutlineUser style={{ fontSize: "1.7em" }} />
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div className="navbar align-self-center d-flex">
              <div className="d-lg-none flex-sm-fill mt-3 mb-4 col-7 col-sm-auto pr-3">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="inputMobileSearch"
                    placeholder="Search ..."
                  />
                  <div className="input-group-text">
                    <AiOutlineSearch style={{ fontSize: "1.7em" }} />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Nav>
    </>
  );
}
