// import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
// import axios from "axios";
// import { SERVER_PATH } from "../../config/env";

// const slideSlides = {
//   height: "300px",
//   width: "100vw",
// };

// export default function Sliders() {
//   const [image, setImage] = useState([]);

//   const fetchData = () => {
//     axios.get(`${SERVER_PATH}get/user_landingpage.php`).then((res) => {
//       setImage(res.data);
//     });
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const settings = {
//     autoplay: true,
//     autoplaySpeed: 5000,
//     dots: false,
//     infinite: true,
//     speed: 2000,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     arrows: false,
//   };

//   return (
//     <>
//       {image.length > 0 ? (
//         <Slider {...settings} className="">
//           {Array.isArray(image) &&
//             image.length > 0 &&
//             image.map((data) => {
//               return (
//                 <div key={data.image_id}>
//                   <a href={data.link} target="blank">
//                     <img
//                       src={data.path.replace(/\\/g, "\\\\")}
//                       alt="ktownaaricreatives offers"
//                       className="img-fluid slider"
//                       style={slideSlides}
//                     />
//                   </a>
//                 </div>
//               );
//             })}
//         </Slider>
//       ) : null}
//     </>
//   );
// }

import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Img1 from '../../assets/img/1.png';
import Img2 from '../../assets/img/2.png';
import Img3 from '../../assets/img/3.png';
import SmallImg1 from '../../assets/img/mb1.png';
import SmallImg2 from '../../assets/img/mb2.jpg';
import SmallImg3 from '../../assets/img/mb3.png';

const slideSlides = {
  height: "300px",
  width: "100vw",
};

// Static data for larger screens
const largeImageData = [
  { image_id: 1, path: Img1, link: "https://ktownaaricreatives.com/" },
  { image_id: 2, path: Img2, link: "https://drive.google.com/drive/folders/1SrvprrguIXtAkR5sSAb3L-aMJxP7qzxr?usp=sharing" },
  { image_id: 3, path: Img3, link: "https://drive.google.com/drive/folders/1SrvprrguIXtAkR5sSAb3L-aMJxP7qzxr?usp=sharing" },
];

// Static data for smaller screens
const smallImageData = [
  { image_id: 1, path: SmallImg1, link: "https://ktownaaricreatives.com/" },
  { image_id: 2, path: SmallImg2, link: "https://drive.google.com/drive/folders/1SrvprrguIXtAkR5sSAb3L-aMJxP7qzxr?usp=sharing" },
  { image_id: 3, path: SmallImg3, link: "https://drive.google.com/drive/folders/1SrvprrguIXtAkR5sSAb3L-aMJxP7qzxr?usp=sharing" },
];

export default function Sliders() {
  const [imageData, setImageData] = useState(largeImageData);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 600px)");
    const handleChange = () => {
      setImageData(mediaQuery.matches ? smallImageData : largeImageData);
    };
    handleChange(); // Set initial data
    mediaQuery.addEventListener("change", handleChange);

    return () => mediaQuery.removeEventListener("change", handleChange);
  }, []);

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      {imageData.length > 0 ? (
        <Slider {...settings} className="">
          {imageData.map((data) => (
            <div key={data.image_id}>
              <a href={data.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={data.path}
                  alt="Slider Offer"
                  className="img-fluid slider"
                  style={slideSlides}
                />
              </a>
            </div>
          ))}
        </Slider>
      ) : null}
    </>
  );
}
