import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import beads from "../../assets/ari-works-img/1.jpg";
import thread from "../../assets/ari-works-img/2.jpg";
import zardosi from "../../assets/ari-works-img/3.jpg";
const titleStyles = {
  fontSize: ".8em",
};

const productImgStyles = {
  height: "140px",
  objectFit: "cover",
};

export default function ProductLayout() {
  const productData = [
    {
      path: "/beads",
      img: beads,
      title: "Aari Material",
    },
    {
      path: "/thread",
      img: thread,
      title: "Aari blouses and Embroidery",
    },
    {
      path: "/zardosi",
      img: zardosi,
      title: " Brooches",
    },
    // {
    //   path: "/customTshirts",
    //   img: productFour,
    //   title: "Customized T-shirts",
    // },
    // {
    //   path: "/trackpants",
    //   img: productFive,
    //   title: "trackpants/shorts",
    // },
  ];
  return (
    <>
      {productData.map((data, index) => {
        return (
          <Col xs={6} className="col-md mb-4" key={index + "product"}>
            <div className="card h-100">
              <Link to={data.path}>
                <img
                  src={data.img}
                  className="card-img-top"
                  alt="ktownaaricreatives products"
                  style={productImgStyles}
                />
              </Link>
              <div className="card-body">
                <Link
                  to={data.path}
                  className="h6 text-decoration-none text-uppercase text-dark"
                  style={titleStyles}
                >
                  {data.title}
                </Link>
              </div>
            </div>
          </Col>
        );
      })}
    </>
  );
}
