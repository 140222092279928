import React from "react";
import { Link } from "react-router-dom";
import { MdCall } from "react-icons/md";
import { GrMail } from "react-icons/gr";
import { AiOutlineInstagram } from "react-icons/ai";
import { HiLocationMarker } from "react-icons/hi";
import { BsFillSuitHeartFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location=useLocation();
  return (
    <>
      <footer id="tempaltemo_footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4 pt-5">
              <h2 className="h2 text-light border-bottom pb-3 border-light logo">
                Ktown Aari Works
              </h2>
              <ul className="list-unstyled text-light footer-link-list">
              {location.pathname !== "/about" && (
    <li>
        <HiLocationMarker className="me-2 mb-1 text-white" />
        NO.38 Sankar Iyyar Thottam,
        <br />
        Sabari Nagar,
        <br />
        Kumbakonam - 612 001.
    </li>
)}

               
                <li>
                  <MdCall className="me-2 mb-1" />
                  <a className="text-decoration-none" href="tel:8610922601">
                    8610922601
                  </a>
                </li>
                {/* <li>
                  <MdCall className="me-2 mb-1" />
                  <a className="text-decoration-none" href="tel:9894341708">
                    9894341708
                  </a>
                </li> */}
                <li>
                  <GrMail className="me-2 mb-1" />
                  <a
                    className="text-decoration-none"
                    href="mailto:ktownaariworks@gmail.com">
                    ktownaariworks@gmail.com
                  </a>
                </li>
                <li>
                  <AiOutlineInstagram className="me-2 mb-1" />
                  <a
                    className="text-decoration-none"
                    href="https://www.instagram.com/ktownaariworks/"
                    target="blank">
                    ktownaariworks
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-4 pt-5">
              <h2 className="h2 text-light border-bottom pb-3 border-light">
                Products
              </h2>
              <ul className="list-unstyled text-light footer-link-list">
                <li>
                  <Link className="text-decoration-none" to="/beads">
                    Aari Material
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/thread">
                  Aari blouses and Embroidery
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/zardosi">
                  Brooches
                  </Link>
                </li>

                {/* <li>
                  <Link className="text-decoration-none" to="/customTshirts">
                    Customized Tshirts
                  </Link>
                </li> */}
              </ul>
            </div>

            <div className="col-md-4 pt-5">
              <h2 className="h2 text-light border-bottom pb-3 border-light">
                Further Info
              </h2>
              <ul className="list-unstyled text-light footer-link-list">
                <li>
                  <Link className="text-decoration-none" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/about">
                    About Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="w-100 bg-black py-3">
          <div className="container">
            <div className="row pt-2">
              <div className="d-flex justify-content-between col-12">
                <p className="text-left text-light">
                  Copyright &copy; 2025 Ktown Aari Works
                </p>
                <a
                  href="https://vebbox.com/"
                  className="text-decoration-none text-light">
                  Developed by vebbox software solutions with passion
                  <BsFillSuitHeartFill
                    className="ms-1"
                    style={{ color: "#c0392b", marginTop: "-5px" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
