import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GrMail } from "react-icons/gr";
import { MdCall } from "react-icons/md";
import {
  AiOutlineInstagram,
  AiOutlineShoppingCart,
  AiOutlineUser,
} from "react-icons/ai";
import "../../assets/css/templatemo.css";
import Logo from "../../assets/ari-works-img/ktown.png";
import NavData from "../NavBar/navData";
import axios from "axios";
import { SERVER_PATH } from "../../config/env";
import { userInfo } from "../../utils/appInfo";
import { FaWhatsapp } from "react-icons/fa";

export default function AppHeader() {
  const [cartCount, setCartCount] = useState([]);

  const getCartCount = async () => {
    await axios
      .post(`${SERVER_PATH}get/cart.php`, {
        user_id: userInfo().email,
      })
      .then((res) => {
        setCartCount(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCartCount();
  }, []);

  return (
    <>
      <Nav
        className="navbar navbar-expand-lg bg-dark navbar-light "
        id="templatemo_nav_top">
        <div className="container-fluid text-light">
          <div className="w-100 d-flex justify-content-between">
            <div>
              <GrMail className="me-2 mb-1" />
              <a
                className="navbar-sm-brand text-light text-decoration-none me-2"
                href="mailto:ktownaariworks@gmail.com">
                ktownaariworks@gmail.com
              </a>
            </div>
            <div>
            <a
                href=" https://wa.me/+918610922601"
                target="blank"
                className="text-light text-decoration-none">
                <FaWhatsapp className="mb-1 me-1" />
                8610922601
              </a>&nbsp;&nbsp;
              <a
                href="https://www.instagram.com/ktownaariworks/"
                target="blank"
                className="text-light text-decoration-none">
                <AiOutlineInstagram className="mb-1 me-1" />
                ktownaariworks
              </a>
            </div>
          </div>
        </div>
      </Nav>
      <Nav
        className="navbar navbar-expand-lg shadow d-none d-lg-block main-nav"
        style={{
          backgroundColor: "#fff",
          position: "sticky",
          top: 0,
          zIndex: 3,
        }}>
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <Link
            className="logo align-self-center"
            to="/"
            style={{ width: "30%" }}>
            <img src={Logo} alt="sivam farms logo" height="auto" width="40%" />
          </Link>

          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#templatemo_main_nav"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="align-self-center collapse navbar-collapse flex-fill  d-lg-flex justify-content-lg-between"
            id="templatemo_main_nav">
            <div className="flex-fill">
              <ul className="nav navbar-nav d-flex justify-content-between mx-lg-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <NavData />
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/cart/:productId"
                    className="nav-icon position-relative text-decoration-none">
                    <AiOutlineShoppingCart style={{ fontSize: "1.7em" }} />
                    {/* <label className="position-absolute top-0 left-100 translate-middle badge rounded-pill bg-danger text-light">
                      {cartCount.length}
                    </label> */}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/profile/:productId"
                    className="nav-icon position-relative text-decoration-none">
                    <AiOutlineUser style={{ fontSize: "1.7em" }} />
                  </Link>
                </li>
              </ul>
            </div>
            
          </div>
        </div>
      </Nav>
      
    </>
  );
}
