import React, { useState, useEffect } from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { SERVER_PATH } from "../../config/env";

export default function NavData() {
  const [data, setData] = useState([]);

  const fetchData = () => {
    axios.post(`${SERVER_PATH}get/all_products.php`, {}).then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const beads = BeadsProducts();

  function BeadsProducts() {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      if (data?.[i]?.category === "beads")
        arr.push({
          productId: data?.[i]?.product_id,
          productName: data?.[i]?.product_name,
        });
    }
    return arr;
  }

  const thread = ThreadProducts();

  function ThreadProducts() {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      if (data?.[i]?.category === "thread")
        arr.push({
          productId: data?.[i]?.product_id,
          productName: data?.[i]?.product_name,
        });
    }
    return arr;
  }

  const zardosi = ZardosiProducts();

  function ZardosiProducts() {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      if (data?.[i]?.category === "zardosi")
        arr.push({
          productId: data?.[i]?.product_id,
          productName: data?.[i]?.product_name,
        });
    }
    return arr;
  }

  const dropDown = [
    {
      title: "Aari Material",
      dropDownTitle: beads,
    },
    {
      title: "Aari blouses and Embroidery",
      dropDownTitle: thread,
    },
    {
      title: "Brooches ",
      dropDownTitle: zardosi,
    },
  ];

  return (
    <>
      {dropDown.map((data, index) => {
        return (
          <li className="nav-item" key={index + "dropdown"}>
            <NavDropdown title={data.title} id="basic-nav-dropdown">
              {data.dropDownTitle.map((d, i) => {
                return (
                  <NavDropdown.Item
                    style={{ fontWeight: "400" }}
                    as={Link}
                    to={`/product/${d.productId}`}
                    key={i + "dropdownItem"}>
                    {d.productName}
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown>
          </li>
        );
      })}
    </>
  );
}
